.hovered {
    transition: transform 0.3s ease;
  }
  
  .hovered:hover {
    transform: scale(1.1);
  }

  .team-image {
    height: 210px; /* Set the desired height here */
  }